import React, { useState, useEffect } from "react";
import allApi from "../../../api/allApi";

const EditUserModal = ({ showPopUp, handleClose, user, showSuccessPopUp }) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setFirstname(user.firstname || "");
      setLastname(user.lastname || "");
      setEmail(user.email || "");
      setAddress(user.address || "");
      setState(user.state || "");
      setSelectedRole(user.role?.name || "");
    }
    fetchRoles();
  }, [user]);

  const fetchRoles = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/user/findAllRoles");
      if (response) {
        setRoles(response);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = async () => {
    try {
      const api = new allApi();
      const userData = {
        email,
        firstname,
        lastname,
        address,
        state,
        role: selectedRole,
      };
      const response = await api.putRequest("api/user/edit", userData);
      if (response) {
        handleClose();
        showSuccessPopUp(response);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <>
      {showPopUp && (
        <div
          className={"modal fade customModal " + (showPopUp ? " show" : "")}
          id="editUserModal"
          style={{ display: showPopUp ? "block" : "none", zIndex: 1009 }}
        >
          <div className="modal-dialog modal-dialog-centered create-usr-pop">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit User</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="firstname" className="form-label">
                        Firstname
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="lastname" className="form-label">
                        Lastname
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control read-only-input"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="role" className="form-label">
                        Role
                      </label>
                      <select
                        className="form-select"
                        id="role"
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                        disabled
                      >
                        <option value="">Select Role</option>
                        {roles.map((role) => (
                          <option key={role.id} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="address" className="form-label">
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="state" className="form-label">
                        State
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="state"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-fill"
                  onClick={handleUpdateUser}
                  aria-label="Close"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditUserModal;
