import React, { useState, useEffect } from "react";
import Loader from "../common/Loader";
import allApi from "../../../api/allApi";
import errorIcon from "../../../assets/images/error-2.png";
import { useNavigate } from "react-router-dom";

function DeviceLink() {
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [individualCheckboxChecked, setIndividualCheckboxChecked] = useState(
    {}
  );
  const [devicesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [deviceLinks, setDeviceLinks] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteCandidates, setDeleteCandidates] = useState([]);
  const [searchQueries, setSearchQueries] = useState({
    link_name: "",
    link_status: "",
    device_count: "",
    link_uptime: "",
    created_at: "",
  });

  useEffect(() => {
    fetctDeviceLink();
  }, [currentPage]);

  const fetctDeviceLink = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/snmp/links/all");
      setDevices(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePerticularLink = (link_id) => {
    navigate(`/link-details`, {
      state: { link_id },
    });
  };

  const handleDelete = async (link_id) => {
    try {
      const api = new allApi();
      const response = await api.deleteRequest(`api/snmp/links/${link_id}`);
      if (response) {
        fetctDeviceLink();
      }
    } catch (error) {
      console.error("Error deleting SNMP templates:", error);
    }
  };

  const handleSelectAllCheckbox = () => {
    setSelectAllChecked((prev) => !prev);
    const newIndividualCheckboxChecked = {};
    filteredDevices.forEach((device) => {
      newIndividualCheckboxChecked[device.link_id] = !selectAllChecked;
    });
    setIndividualCheckboxChecked(newIndividualCheckboxChecked);
  };

  const handleIndividualCheckboxChange = (link_id) => {
    setIndividualCheckboxChecked((prev) => ({
      ...prev,
      [link_id]: !prev[link_id],
    }));
  };

  const handleDeleteButtonClick = () => {
    const selectedLinks = Object.keys(individualCheckboxChecked).filter(
      (key) => individualCheckboxChecked[key]
    );
    if (selectedLinks.length > 0) {
      handleDelete(selectedLinks);
      setShowDeleteConfirmation(true);
    }
  };
  const confirmDelete = () => {
    handleDelete(deleteCandidates);
    setShowDeleteConfirmation(false);
    setDeleteCandidates([]);
  };

  const filteredDevices = devices.filter((device) => {
    const formattedDevice = {
      ...device,
     created_at: new Date(device.created_at).toLocaleString(), // Ensure date is formatted
     link_uptime: device.link_uptime.toString(),// Ensure link_uptime is a string
    };
  
    return Object.keys(searchQueries).every((key) =>
      formattedDevice[key]
        ?.toString()
        .toLowerCase()
        .includes(searchQueries[key].toLowerCase())
    );
  });

  const handleSearchChange = (e, field) => {
    setSearchQueries({ ...searchQueries, [field]: e.target.value });
  };
  
  const handleSearchBox = (header) => {
    console.log("Clicked on header:", header);
  };

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const currentDevices = filteredDevices.slice(
    indexOfFirstDevice,
    indexOfLastDevice
  );
  const totalFilteredDevices = filteredDevices.length;
  const totalPages = Math.ceil(totalFilteredDevices / devicesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Device Link</h3>
            <div className="inc-card-button">
              <button
                className="icon-btn"
                title="Refresh"
                onClick={() => setLoading(true)}
              >
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <button
                className="icon-btn"
                title="Delete"
                onClick={handleDeleteButtonClick}
                data-bs-toggle="modal"
                data-bs-target="#deleteConfirmationModal"
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className="inc-card-table">
              <table className="full-table m-head">
                <thead>
                  <tr>
                    <th>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={selectAllChecked}
                          onChange={handleSelectAllCheckbox}
                        />
                        <span className="indicator"></span>
                      </label>
                    </th>
                    <th>Link Name</th>
                    <th>No. Of Device</th>
                    <th>Link Uptime</th>
                    <th>Created Time</th>
                    <th>Link Status</th>
                    <th>Action</th>
                  </tr>
                  <tr className="search-border">
                    <td></td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="link_name"
                        value={searchQueries.link_name}
                        onChange={(e) => handleSearchChange(e, "link_name")}
                      />
                      <i
                        className="fa fa-sort-amount-desc"
                        onClick={() => handleSearchChange("link_name")}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="link_status"
                        value={searchQueries.link_status}
                        onChange={(e) => handleSearchChange(e, "link_status")}
                      />
                      <i
                        className="fa fa-sort-amount-desc"
                        onClick={() => handleSearchChange("link_status")}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="device_count"
                        value={searchQueries.device_count}
                        onChange={(e) => handleSearchChange(e, "device_count")}
                      />
                      <i
                        className="fa fa-sort-amount-desc"
                        onClick={() => handleSearchChange("device_count")}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="link_uptime"
                        value={searchQueries.link_uptime}
                        onChange={(e) => handleSearchChange(e, "link_uptime")}
                      />
                      <i
                        className="fa fa-sort-amount-desc"
                        onClick={() => handleSearchChange("link_uptime")}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="created_at"
                        value={searchQueries.created_at}
                        onChange={(e) => handleSearchChange(e, "created_at")}
                      />
                      <i
                        className="fa fa-sort-amount-desc"
                        onClick={() => handleSearchChange("created_at")}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td colSpan="7"></td>
                  </tr>
                </thead>
                <tbody>
                  {currentDevices?.map((device) => (
                    <tr key={device.link_id}>
                      <td>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={
                              individualCheckboxChecked[device.link_id] || false
                            }
                            onChange={() =>
                              handleIndividualCheckboxChange(device.link_id)
                            }
                          />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>
                        <span
                          className="device-info0 underline"
                          onClick={() => handlePerticularLink(device.link_id)}
                        >
                          {device.link_name || "N/A"}
                        </span>
                      </td>
                      <td>{device.device_count || "N/A"}</td>
                      <td>{device.link_uptime || "N/A"}</td>
                      <td>{device.created_at || "N/A"}</td>
                      <td>
                        <span
                          className={
                            "d-status " +
                            (device?.link_status === 1 ? "offline" : "online")
                          }
                        >
                          {device?.link_status === 1 ? "Inactive" : "Active"}
                        </span>
                      </td>

                      <td>
                        <div className="button-group">
                          <button
                            title="Delete"
                            data-bs-toggle="modal"
                            data-bs-target="#noLinkeSelectedPopup"
                            onClick={() => {
                              setDeleteCandidates([device.link_id]);
                              setShowDeleteConfirmation(true);
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="pagination-container">
              <div className="pagination-info">
                <p>
                  Showing{" "}
                  {Math.min(currentPage * devicesPerPage, totalFilteredDevices)}{" "}
                  of {totalFilteredDevices} records
                </p>
              </div>
              <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                  Previous
                </button>
                {[...Array(Math.min(totalPages, 10)).keys()].map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageClick(page + 1)}
                    className={currentPage === page + 1 ? "active" : ""}
                  >
                    {page + 1}
                  </button>
                ))}
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
                <button
                  onClick={handleLastPage}
                  disabled={currentPage === totalPages}
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`modal fade ${showDeleteConfirmation ? "show" : ""}`}
        id="deleteConfirmationModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteConfirmation ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg text-center">
              <img src={errorIcon} alt="error" />
              <p>Are you sure you want to delete?</p>
              <div className="d-flex justify-content-center">
                <button
                  className="text-btn primary-btn mx-2"
                  data-bs-dismiss="modal"
                  onClick={confirmDelete}
                >
                  Yes
                </button>
                <button
                  className="text-btn mx-2"
                  data-bs-dismiss="modal"
                  onClick={() => setShowDeleteConfirmation(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceLink;
