import React, { useState, useEffect } from "react";
import dummyGraph from "../../../assets/images/data-usage.png";
import Chart from "react-apexcharts";

function WirelessDashboard() {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const response = {
      response: {
        status: true,
        resultCode: 1,
        responseCode: "200",
        message: "success",
        failed: 0,
        success: 0,
      },
      details: {
        ssidList: [
          {
            _id: "669de568aca2d557d23b2a6c",
            ssidName: "ssid1",
          },
          {
            _id: "669df85136a05d0de9d311b9",
            ssidName: "wifi123",
          },
          {
            _id: "669e2fbf0cf6d34879865722",
            ssidName: "ssid2",
          },
          {
            _id: "669f712d0cf6d3487986d875",
            ssidName: "newhens",
          },
        ],
        deviceList: [
          {
            serialNo: "7CK1387WD0100",
            deviceName: "WBE750",
            deviceType: "AP",
            model: "WBE750",
          },
          {
            serialNo: "7CK1367SD001B",
            deviceName: "WBE750",
            deviceType: "AP",
            model: "WBE750",
          },
        ],
      },
    };

    // Generate random data for each SSID
    const deviceData = response.details.deviceList.map((device) => ({
      deviceName: device.deviceName,
      usage: {
        ts: new Date().toISOString(),
        rx: Math.floor(Math.random() * 1), // Random RX data (0 to 1)
        tx: Math.floor(Math.random() * 1), // Random TX data (0 to 1)
      },
    }));

    const categories = deviceData.map((item) => item.usage.ts);

    const seriesData = response.details.deviceList.map((device, index) => ({
      name: device.deviceName,
      data: deviceData
        .filter((data) => data.deviceName === device.deviceName)
        .map((data) => ({ x: data.usage.ts, y: data.usage.rx })),
      color: index === 0 ? "#fc7594" : "#00aaff", // Hex color codes
      lineStyle: index === 0 ? "solid" : "dashed", // Different line styles
    }));
    setCategories(categories);
    setSeries(seriesData);
  }, []);

  const options = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false, // Ensure toolbar is hidden
      },
    },
    xaxis: {
      type: "datetime",
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      title: {
        text: "No. Of Clients",
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: series.map((_, index) => (index === 0 ? "#fc7594" : "#00aaff")),
    zoom: {
      enabled: false, // Disable zoom controls
    },
    toolbar: {
      show: false, // Remove all toolbar controls
    },
  };

  const Trafficoptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false, // Ensure toolbar is hidden
      },
    },
    xaxis: {
      type: "datetime",
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      title: {
        text: "Traffic (KBPS)",
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: series.map((_, index) => (index === 0 ? "#fc7594" : "#00aaff")),
    zoom: {
      enabled: false, // Disable zoom controls
    },
    toolbar: {
      show: false, // Remove all toolbar controls
    },
  };
  const Channeloptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false, // Ensure toolbar is hidden
      },
    },
    xaxis: {
      type: "datetime",
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      title: {
        text: "Channel (%)",
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: series.map((_, index) => (index === 0 ? "#fc7594" : "#00aaff")),
    zoom: {
      enabled: false, // Disable zoom controls
    },
    toolbar: {
      show: false, // Remove all toolbar controls
    },
  };
  const WirelessDataoptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // Ensure toolbar is hidden
      },
    },
    xaxis: {
      type: "datetime",
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      title: {
        text: "Data Usage (MB)",
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: series.map((_, index) => (index === 0 ? "#fc7594" : "#00aaff")),
    zoom: {
      enabled: false, // Disable zoom controls
    },
    toolbar: {
      show: false, // Remove all toolbar controls
    },
  };
  return (
    <div>
      <div className="row mb-4">
        <div className="col-lg-6">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Clients</h3>
              <span
                className="dropdown"
                id="clientDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  All <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="clientDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      2.4 HHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      5 GHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      6 GHz
                    </a>
                  </li>
                </ul>
              </span>
              <span
                className="dropdown"
                id="clientDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  Monthly{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="clientDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Hourly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Weekly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Monthly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Yearly
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              <Chart
                options={options}
                series={series}
                type="line"
                height={135}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Traffic Bandwidth</h3>
              <span
                className="dropdown"
                id="clientDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  All <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="clientDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      2.4 HHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      5 GHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      6 GHz
                    </a>
                  </li>
                </ul>
              </span>
              <span
                className="dropdown"
                id="trafficDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  Monthly{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="trafficDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Hourly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Weekly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Monthly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Yearly
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              <Chart
                options={Trafficoptions}
                series={series}
                type="line"
                height={135}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Connected Clients per SSID</h3>
              <span
                className="dropdown"
                id="clientDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  All <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="clientDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      2.4 HHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      5 GHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      6 GHz
                    </a>
                  </li>
                </ul>
              </span>
              <span
                className="dropdown"
                id="ssidDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  Monthly{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="ssidDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Hourly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Weekly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Monthly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Yearly
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              <img src={dummyGraph} alt="Dummy Graph" />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Wireless Data Consumption</h3>
              <span
                className="dropdown"
                id="clientDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  All <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="clientDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      2.4 HHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      5 GHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      6 GHz
                    </a>
                  </li>
                </ul>
              </span>
              <span
                className="dropdown"
                id="wirelessDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  Monthly{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="wirelessDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Hourly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Weekly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Monthly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Yearly
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              <Chart
                options={WirelessDataoptions}
                series={series}
                type="bar"
                height={135}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Client Data Consumption</h3>
              <span
                className="dropdown"
                id="clientDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  All <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="clientDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      2.4 HHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      5 GHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      6 GHz
                    </a>
                  </li>
                </ul>
              </span>
              <span
                className="dropdown"
                id="dataDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  Monthly{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dataDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Hourly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Weekly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Monthly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Yearly
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              <img src={dummyGraph} alt="Dummy Graph" />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Channel Utilization</h3>
              <span
                className="dropdown"
                id="clientDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  All <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="clientDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      2.4 HHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      5 GHz
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      6 GHz
                    </a>
                  </li>
                </ul>
              </span>
              <span
                className="dropdown"
                id="channelDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  Monthly{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="channelDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Hourly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Weekly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Monthly
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Yearly
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              <Chart
                options={Channeloptions}
                series={series}
                type="line"
                height={135}
              />{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Client List</h3>
              <div className="inc-card-button">
                <button className="icon-btn" title="Refresh">
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </button>
                <button className="icon-btn" title="Add">
                  <i className="fa fa-filter" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div className="inc-card-body">
              <div className="inc-card-table">
                <table className="m-head">
                  <thead>
                    <tr>
                      <th>Clients</th>
                      <th>Connection</th>
                      <th>Associated Device</th>
                      <th>Device Model</th>
                      <th>SSID</th>
                      <th>Port</th>
                      <th>OS</th>
                      <th>Mac Address</th>
                      <th>IP Address</th>
                      <th>RSSI</th>
                      <th>Resume/Pause</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>6LL21A2500EF5</td>
                      <td>-</td>
                      <td>77W0A6500AS</td>
                      <td>GS75TPFv3</td>
                      <td>N/A</td>
                      <td>3</td>
                      <td>N/A</td>
                      <td>6c:cd:fg:65:fc:1f</td>
                      <td>192.168.8.140</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td>6LL21A2500EF5</td>
                      <td>-</td>
                      <td>77W0A6500AS</td>
                      <td>GS75TPFv3</td>
                      <td>N/A</td>
                      <td>3</td>
                      <td>N/A</td>
                      <td>6c:cd:fg:65:fc:1f</td>
                      <td>192.168.8.140</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td>6LL21A2500EF5</td>
                      <td>-</td>
                      <td>77W0A6500AS</td>
                      <td>GS75TPFv3</td>
                      <td>N/A</td>
                      <td>3</td>
                      <td>N/A</td>
                      <td>6c:cd:fg:65:fc:1f</td>
                      <td>192.168.8.140</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td>6LL21A2500EF5</td>
                      <td>-</td>
                      <td>77W0A6500AS</td>
                      <td>GS75TPFv3</td>
                      <td>N/A</td>
                      <td>3</td>
                      <td>N/A</td>
                      <td>6c:cd:fg:65:fc:1f</td>
                      <td>192.168.8.140</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Devices (5)</h3>
              <div className="inc-card-button">
                <button className="icon-btn" title="Refresh">
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </button>
                <button className="icon-btn" title="Add">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div className="inc-card-body">
              <div className="inc-card-table">
                <table className="m-head">
                  <thead>
                    <tr>
                      <th>Serial Number</th>
                      <th>Product Class</th>
                      <th>Status</th>
                      <th>Software Version</th>
                      <th>IP</th>
                      <th>SSID</th>
                      <th>UP Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>000000001</td>
                      <td>BM63w-1</td>
                      <td>
                        <span class="d-status online">Online</span>
                      </td>
                      <td>V100R001RQC56B017</td>
                      <td>172.3.89.139</td>
                      <td>404 WiMAX Not Found</td>
                      <td>12/21/2023, 4:56:18 PM</td>
                    </tr>
                    <tr>
                      <td>000000001</td>
                      <td>BM63w-1</td>
                      <td>
                        <span class="d-status offline">Offline</span>
                      </td>
                      <td>V100R001RQC56B017</td>
                      <td>172.3.89.139</td>
                      <td>404 WiMAX Not Found</td>
                      <td>12/21/2023, 4:56:18 PM</td>
                    </tr>
                    <tr>
                      <td>000000001</td>
                      <td>BM63w-1</td>
                      <td>
                        <span class="d-status online">Online</span>
                      </td>
                      <td>V100R001RQC56B017</td>
                      <td>172.3.89.139</td>
                      <td>404 WiMAX Not Found</td>
                      <td>12/21/2023, 4:56:18 PM</td>
                    </tr>
                    <tr>
                      <td>000000001</td>
                      <td>BM63w-1</td>
                      <td>
                        <span class="d-status online">Online</span>
                      </td>
                      <td>V100R001RQC56B017</td>
                      <td>172.3.89.139</td>
                      <td>404 WiMAX Not Found</td>
                      <td>12/21/2023, 4:56:18 PM</td>
                    </tr>
                    <tr>
                      <td>000000001</td>
                      <td>BM63w-1</td>
                      <td>
                        <span class="d-status offline">Offline</span>
                      </td>
                      <td>V100R001RQC56B017</td>
                      <td>172.3.89.139</td>
                      <td>404 WiMAX Not Found</td>
                      <td>12/21/2023, 4:56:18 PM</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WirelessDashboard;
