import React from "react";
import internetOpenImage from "../../../assets/images/internet_open.svg";
import routerOpenImage from "../../../assets/images/router_open.svg";
import clientsGenericImage from "../../../assets/images/clients_generic.svg";
import topoLogy1 from "../../../assets/images/topology-1.png";
import topoLogy2 from "../../../assets/images/topology-2.png";
import topoLogy3 from "../../../assets/images/topology-3.png";
import topoLogy4 from "../../../assets/images/topology-4.png";
import topoLogy5 from "../../../assets/images/topology-5.png";
import apImage from "../../../assets/images/ap_open.svg";

function Topology() {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Topology</h3>
          </div>
          <div className="inc-card-body">
            <div className="topology-draft">           
              <img src={topoLogy1} alt="topoLogy1"/>
              <img src={topoLogy2} alt="topoLogy2"/>
              <img src={topoLogy3} alt="topoLogy3"/>
              <img src={topoLogy4} alt="topoLogy4"/>
              <img src={topoLogy5} alt="topoLogy5"/>
            </div>
            {/* <svg
              id="treeImage"
              viewBox="0,0,1627,758"
              width="1627"
              height="758"
              style={{ cursor: "all-scroll" }}
            >
              <g transform="translate(90,190) scale(1)">
                <path
                  id="path21"
                  className="topologyLink2"
                  d="M 220 0
                    C 110 0,
                    110 0,
                    0 0"
                  style={{ opacity: 1, strokeWidth: 2 }}
                ></path>
                <path
                  id="path32"
                  className="topologyLink2"
                  d="M 440 0
                    C 330 0,
                    330 0,
                    220 0"
                  style={{ opacity: 1, strokeWidth: 2 }}
                ></path>
                <path
                  id="path43"
                  className="topologyLink2"
                  d="M 660 -25
                    C 550 -25,
                    550 0,
                    440 0"
                  style={{ opacity: 1, strokeWidth: 2 }}
                ></path>
                <path
                  id="path53"
                  className="topologyLink2"
                  d="M 660 25
                    C 550 25,
                    550 0,
                    440 0"
                  style={{ opacity: 1, strokeWidth: 2 }}
                ></path>
                <text
                  font-family="Nunito Sans"
                  font-size="12px"
                  fill="#949494"
                  className="hide"
                  id="link21"
                  transform="translate(165,-15)"
                  dy=".35em"
                  text-anchor="right"
                ></text>
                <text
                  font-family="Nunito Sans"
                  font-size="12px"
                  fill="#949494"
                  className="hide"
                  id="link32"
                  transform="translate(385,-15)"
                  dy=".35em"
                  text-anchor="right"
                >
                  Port 1
                </text>
                <text
                  font-family="Nunito Sans"
                  font-size="12px"
                  fill="#949494"
                  className="hide"
                  id="link43"
                  transform="translate(605,-40)"
                  dy=".35em"
                  text-anchor="right"
                ></text>
                <text
                  font-family="Nunito Sans"
                  font-size="12px"
                  fill="#949494"
                  className="hide"
                  id="link53"
                  transform="translate(605,10)"
                  dy=".35em"
                  text-anchor="right"
                ></text>
                <text
                  font-family="Nunito Sans"
                  font-size="12px"
                  fill="#949494"
                  className="hide"
                  id="link12"
                  transform="translate(20,-15)"
                  dy=".35em"
                  text-anchor="right"
                ></text>
                <text
                  font-family="Nunito Sans"
                  font-size="12px"
                  fill="#949494"
                  className="hide"
                  id="link23"
                  transform="translate(240,-15)"
                  dy=".35em"
                  text-anchor="right"
                ></text>
                <text
                  font-family="Nunito Sans"
                  font-size="12px"
                  fill="#949494"
                  className="hide"
                  id="link34"
                  transform="translate(460,-15)"
                  dy=".35em"
                  text-anchor="right"
                ></text>
                <text
                  font-family="Nunito Sans"
                  font-size="12px"
                  fill="#949494"
                  className="hide"
                  id="link35"
                  transform="translate(460,-15)"
                  dy=".35em"
                  text-anchor="right"
                ></text>
                <path
                  id="21"
                  className="topologyLink22"
                  d="M 220 0
                    C 110 0,
                    110 0,
                    0 0"
                ></path>
                <path
                  id="32"
                  className="topologyLink22"
                  d="M 440 0
                    C 330 0,
                    330 0,
                    220 0"
                ></path>
                <path
                  id="43"
                  className="topologyLink22"
                  d="M 190
                    C 120 -25,
                    150 0,
                    140 0"
                ></path>
                <path
                  id="53"
                  className="topologyLink22"
                  d="M 190
                    C 120 -25,
                    150 0,
                    140 0"
                ></path>
                <g
                  className="node"
                  transform=""
                  style={{ opacity: 1, strokeWidth: 2 }}
                >
                  <circle
                    cy="0"
                    cx="0"
                    r="0"
                    stroke-width="2"
                    fill="#fff"
                    stroke="#fc5373"
                  ></circle>
                  <image
                    href={internetOpenImage}
                    x="-13"
                    y="-13"
                    width="26"
                    height="26"
                    style={{ cursor: "pointer" }}
                  ></image>
                  <text
                    dy=".35em"
                    x="-20"
                    text-anchor="end"
                    fill="#2c262d"
                    style={{
                      fontWeight: 600,
                      fontFamily: "Nunito Sans",
                      fontSize: "14px",
                    }}
                  >
                    ISP
                  </text>
                </g>
                <g
                  className="node"
                  transform="translate(220, 0)"
                  style={{ opacity: 1, strokeWidth: 2 }}
                >
                  <circle
                    cy="0"
                    cx="0"
                    r="16"
                    stroke-width="2"
                    fill="#fff"
                    stroke="#06d872"
                  ></circle>
                  <image
                    href={routerOpenImage}
                    x="-13"
                    y="-13"
                    width="26"
                    height="26"
                    style={{ cursor: "pointer" }}
                  ></image>
                  <text
                    dy=".35em"
                    x="-20"
                    text-anchor="end"
                    fill="#2c262d"
                    style={{
                      fontWeight: 600,
                      fontFamily: "Nunito Sans",
                      fontSize: "14px",
                    }}
                  >
                    pr60x-HQ
                  </text>
                </g>
                <g
                  className="node"
                  transform="translate(440, 0)"
                  style={{ opacity: 1, strokeWidth: 2 }}
                >
                  <circle
                    cy="0"
                    cx="0"
                    r="16"
                    stroke-width="2"
                    fill="#fff"
                    stroke="#06d872"
                  ></circle>
                  <image
                    href={clientsGenericImage}
                    x="-13"
                    y="-13"
                    width="26"
                    height="26"
                    style={{ cursor: "pointer" }}
                  ></image>
                  <text
                    dy=".35em"
                    x="-20"
                    text-anchor="end"
                    fill="#2c262d"
                    style={{
                      fontWeight: 600,
                      fontFamily: "Nunito Sans",
                      fontSize: "14px",
                    }}
                  >
                    aabbcc-108tup-H...
                  </text>
                </g>
                <g
                  className="node"
                  transform="translate(660, -25)"
                  style={{ opacity: 1, strokeWidth: 2 }}
                >
                  <circle
                    cy="0"
                    cx="0"
                    r="16"
                    stroke-width="2"
                    fill="#fff"
                    stroke="#06d872"
                  ></circle>
                  <image
                    href={apImage}
                    x="-13"
                    y="-13"
                    width="26"
                    height="26"
                    style={{ cursor: "pointer" }}
                  ></image>
                  <text
                    dy=".35em"
                    x="20"
                    text-anchor="start"
                    fill="#2c262d"
                    style={{
                      fontWeight: 600,
                      fontFamily: "Nunito Sans",
                      fontSize: "14px",
                    }}
                  >
                    AABBCC-710-HQ
                  </text>
                </g>
                <g
                  className="node"
                  transform="translate(660, 25)"
                  style={{ opacity: 1, strokeWidth: 2 }}
                >
                  <circle
                    cy="0"
                    cx="0"
                    r="16"
                    stroke-width="2"
                    fill="#fff"
                    stroke="#06d872"
                  ></circle>
                  <image
                    href={apImage}
                    x="-13"
                    y="-13"
                    width="26"
                    height="26"
                    style={{ cursor: "pointer" }}
                  ></image>
                  <text
                    dy=".35em"
                    x="20"
                    text-anchor="start"
                    fill="#2c262d"
                    style={{
                      fontWeight: 600,
                      fontFamily: "Nunito Sans",
                      fontSize: "14px",
                    }}
                  >
                    AABBCC-605-HQ
                  </text>
                </g>
              </g>
            </svg> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topology;
