// Header.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AllApi from "../../../api/allApi";
import dropArrow from "../../../assets/images/dropArrow.png";

export default function Header({ heading }) {
  const navigate = useNavigate();
  const [nameInitials, setNameInitials] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const theme_value = sessionStorage.getItem("theme_key");
    if(theme_value=="dark"){
      document.documentElement.setAttribute('data-theme', 'dark');
      document.getElementById('theme').checked=true;
    }
    else{
      document.documentElement.setAttribute('data-theme', 'light');
    }
    const userName = sessionStorage.getItem("username");
    setUserName(userName);    
    themeChange();

    if (userName) {
      const [firstName, lastName] = userName.split(" ");
      const initials = `${firstName.charAt(0).toUpperCase()}${lastName
        .charAt(0)
        .toUpperCase()}`;
      setNameInitials(initials);
    }
  }, []);

  const logout = () => {
    const allApi = new AllApi();
    allApi
      .logout("api/auth/logout")
      .then((response) => {
        sessionStorage.removeItem("sessionToken");
      })
      .catch((error) => {
        console.error("Logout Error:", error);
      });
    navigate("/");
  };

const themeChange = ()=>{
  const toggleSwitch = document.getElementById('theme');
  function switchTheme(e) {
    if(e.target.checked) {
      document.documentElement.setAttribute('data-theme', 'dark');
      sessionStorage.setItem("theme_key", "dark");
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      sessionStorage.setItem("theme_key", "light");
    }
  }   
  toggleSwitch.addEventListener('change', switchTheme, false);
}


  return (
    <header id="top-bar">
      <div className="header-title">{heading}</div>
      <div className="header-control">
        <div className="switch-box">
          <label className="ice-switch">
            <input type="checkbox" className="theme" id="theme"/>
            <span className="slider"></span>
          </label>
          <span>Dark Mode</span>
        </div>
        <div className="header-notification">
          <i className="icon notifications"></i>
        </div>
        <div className="name-post">
          <strong>{userName}</strong>
          {/* <p>Product Manager</p> */}
        </div>
        <div className="user-access">
          <div data-bs-toggle="dropdown" aria-expanded="false">
            <div className="header-profile-user">
              <span className="profile-user dark">{nameInitials}</span>
              <span className="drop">
                <img src={dropArrow} width="12" alt="" />
              </span>
            </div>
          </div>
          <div className="dropdown-menu dropdown-menu-right">
            <button className="dropdown-item">Profile Settings</button>
            <button className="dropdown-item" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
