import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();

class allApi {
  constructor() {
    this.authToken = this.getToken();
  }

  async fetch(url, options = {}) {
    const token = this.getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token != undefined) {
      headers.Authorization = `Bearer ${token}`;
    }
    try {
      const baseUrl = window.location.origin;
      const response = await axios(`${baseUrl}/${url}`, {
        ...options,
        headers,
      });

      return response.data;
    } catch (error) {
      console.error("API Request Error:", error);
      throw error;
    }
  }

  setToken(token) {
    cookies.set("vvdnTech", token, { path: "/" });
  }

  getToken() {
    return sessionStorage.getItem("sessionToken");
  }

  // login api call
  async login(endpoint, data) {
    try {
      const response = await this.fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
        credentials: "include",
        body: JSON.stringify(data),
      });
      const { status, data: responseData } = response;
      if (status === 200) {
        this.setToken(responseData?.data?.token);
      }
      return response;
    } catch (error) {
      console.error("Login Error:", error);
      throw error;
    }
  }

  async genericSearch(endpoint, query) {
    const queryParams = new URLSearchParams({ search: query }).toString();
    const searchEndpoint = `${endpoint}?${queryParams}`;

    try {
      return await this.getRequest(searchEndpoint);
    } catch (error) {
      console.error(`Error with search request at ${searchEndpoint}:`, error);
      throw error;
    }
  }

  // separate api calls for different types of requests

  async postRequest(endpoint, userEmail, payload) {
    let urlWithParams;
    if (endpoint === "api/forgotPassword") {
      urlWithParams = `${endpoint}?email=${userEmail.email}`;
    } else {
      urlWithParams = `${endpoint}`;
    }

    const requestBody = {
      data: userEmail.email,
    };
    if (payload !== null) {
      requestBody.payload = payload;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getToken()}`,
      },
      credentials: "include",
      data: requestBody.payload,
      body: JSON.stringify(requestBody),
    };
    try {
      const response = await this.fetch(urlWithParams, requestOptions);
      return response;
    } catch (error) {
      console.error("Error in postRequest:", error);
      throw error;
    }
  }

  async patchRequest(endpoint, data) {
    return this.fetch(endpoint, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getToken()}`,
      },
      body: JSON.stringify(data),
    });
  }

  async webSoc(endpoint, data) {
    return this.fetch(endpoint, {
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": window.location.origin,
      },
      body: JSON.stringify(data),
    });
  }

  async getRequest(endpoint, payload, includeToken = true) {
    const headers = {
      "Content-Type": "application/json",
    };

    if (includeToken) {
      const token = this.getToken();
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
    }

    try {
      const queryParams = payload
        ? new URLSearchParams(payload).toString()
        : "";
      const urlWithParams = payload ? `${endpoint}?${queryParams}` : endpoint;
      return await this.fetch(urlWithParams, {
        method: "GET",
        mode: "cors",
        headers,
        cache: "no-store",
      });
    } catch (error) {
      console.error("GET Request Error:", error);
      throw error;
    }
  }

  async putRequest(endpoint, payload) {
    let urlWithParams = `${endpoint}`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getToken()}`,
      },
      credentials: "include",
      data: payload,
    };
    try {
      const response = await this.fetch(urlWithParams, requestOptions);
      return response;
    } catch (error) {
      console.error("Error in putRequest:", error);
      throw error;
    }
  }

  // logout api call
  async logout(endpoint, data) {
    return this.fetch(endpoint, { method: "POST", body: JSON.stringify(data) });
  }

  // DELETE API
  async deleteRequest(endpoint, payload) {
    const authToken = this.getToken();
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };

    try {
      const baseUrl = window.location.origin;
      const response = await axios.delete(`${baseUrl}/${endpoint}`, {
        headers: headers,
        data: payload,
      });

      return response.data;
    } catch (error) {
      console.error("API Request Error:", error);
      throw error;
    }
  }
}

export default allApi;
