import React from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";

function Layout({ children, heading}) {
  return (
    <div className="app-layout">
      <div id="layout-wrapper">
        <Header heading={heading}/>
        <Sidebar />
        <main className="body-container">{children}</main>
      </div>
    </div>
  );
}

export default Layout;
