import React, { useState, useEffect } from "react";
import AllApi from "../../../api/allApi";
import Loader from "../common/Loader";
import clientGraph from "../../../assets/images/Graph.png";
import edgeGraph from "../../../assets/images/edge-chart.png";
import cpuIcon from "../../../assets/images/cpu.png";
import memoryIcon from "../../../assets/images/memory.png";
import diskIcon from "../../../assets/images/disk.png";
import dbIcon from "../../../assets/images/db.png";
import thumUp from "../../../assets/images/thumb-up.png";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useNavigate, useLocation } from "react-router-dom";
import { Progress } from "antd";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [deviceData, setDeviceData] = useState({
    onlineCount: 0,
    offlineCount: 0,
    totalDevices: 0,
    waitingForConnectionCount: 0,
  });
  //   const [containerData, setContainerData] = useState({
  //     cpu_usage: 20,
  //     memory_usage: 0.78,
  //     storage_usge: 0.18,
  //     db_connection: 30,
  //   });
  const [faults, setFaults] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [healthStatus, setHealthStatus] = useState("All");
  const [trendData, setTrendData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("monthly");
  const [containers, setContainers] = useState([]);
  const [selectedContainerKey, setSelectedContainerKey] = useState("");
  const [pieChartData, setPieChartData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedContainerData, setSelectedContainerData] = useState(null);
  const [edgeCounts, setEdgeCounts] = useState({});
  const [selectedContainerId, setSelectedContainerId] = useState("");
  const [containerData, setContainerData] = useState(null);
  const [containerList, setContainerList] = useState([]);

  useEffect(() => {
    fetchData();
    fetchEdgeData();
    fetchFaultsOverview();
    handleDeviceTrends();
    updatePieChartData(containerData);
  }, [selectedInterval, containerData]);

  useEffect(() => {}, [edgeCounts]);

  useEffect(() => {
    const fetchContainerList = async () => {
      try {
        const response = await fetch("snmp/container_list/");
        const data = await response.json();
        const formattedData = data.data.map((container) => ({
          ...container,
          container_name: formatContainerName(container.container_name),
        }));
        setContainerList(formattedData);
        if (formattedData.length > 0) {
          setSelectedContainerId(formattedData[0].container_id);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching container list:", error);
        setLoading(false);
      }
    };

    fetchContainerList();
  }, []);

  useEffect(() => {
    const fetchContainerData = async () => {
      if (selectedContainerId) {
        setLoading(true);
        try {
          const response = await fetch(
            `snmp/container_info/?container_id=${selectedContainerId}`
          );
          const data = await response.json();
          const containerInfo = Object.values(data.data)[0];
          setContainerData(containerInfo);
          updatePieChartData(containerInfo);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching container data:", error);
          setLoading(false);
        }
      }
    };

    fetchContainerData();
  }, [selectedContainerId]);

  const handleDropdownChange = (event) => {
    setSelectedContainerId(event.target.value);
  };

  const formatContainerName = (name) => {
    return name
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fetchData = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const health = searchParams.get("health") || "All";
      setHealthStatus(health);

      const api = new AllApi();
      const response = await api.getRequest("api/devices");
      if (response) {
        let filteredDevices = response;
        if (health !== "All") {
          filteredDevices = response.filter(
            (device) => device.health === health
          );
        }
        setDeviceData({
          onlineCount: filteredDevices.filter(
            (device) => device.health === "Online"
          ).length,
          offlineCount: filteredDevices.filter(
            (device) => device.health === "Offline"
          ).length,
          totalDevices: filteredDevices.length,
          waitingForConnectionCount: 1,
          totalDevices: filteredDevices.length,
          waitingForConnectionCount: 1,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchFaultsOverview = async () => {
    try {
      const api = new AllApi();
      const response = await api.getRequest("api/alarms/overview");
      setFaults(response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching faults overview:", error);
      setLoading(false);
    }
  };

  const handleDeviceTrends = async () => {
    setLoading(true);
    try {
      const api = new AllApi();
      const response = await api.getRequest(
        `api/deviceTrend/${selectedInterval}`
      );
      if (response) {
        const transformedData = {};
        response.forEach(({ interval, device_type, net_count }) => {
          const formattedInterval = formatInterval(interval);
          if (!transformedData[formattedInterval]) {
            transformedData[formattedInterval] = {
              interval: formattedInterval,
            };
          }
          transformedData[formattedInterval][device_type] = net_count;
        });
        setTrendData(Object.values(transformedData));
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching trend data:", error);
      setLoading(false);
    }
  };

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };

  const fetchEdgeData = async () => {
    try {
      const api = new AllApi();
      const response = await api.getRequest("api/gateway/edge_info");
      const { activeCount, inactiveCount, managedByGateway } = response;
      const totalCount = activeCount + inactiveCount;
      setEdgeCounts({
        activeCount,
        inactiveCount,
        managedByGateway,
        totalCount,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching edge data:", error);
      setLoading(false);
    }
  };

  const onlinePercentage =
    (deviceData.onlineCount / deviceData.totalDevices) * 100;
  const offlinePercentage =
    (deviceData.offlineCount / deviceData.totalDevices) * 100;
  const waitingForConnectionCount = 1;

  const data = [
    { name: "Online", value: deviceData.onlineCount },
    { name: "Offline", value: deviceData.offlineCount },
    { name: "Connecting", value: waitingForConnectionCount },
  ];
  const COLORS = ["#26BF78", "#FF6B6B", "#eb8919"];

  const formatSeverityLevel = (severityLevel) => {
    switch (severityLevel) {
      case "CRITICAL":
        return "Critical";
      case "MAJOR":
        return "Major";
      case "MINOR":
        return "Minor";
      case "INFO":
        return "Info";
      default:
        return severityLevel;
    }
  };

  const faultsData = Array.isArray(faults)
    ? faults.map(({ alarmSeverityLevel, count }) => ({
        name: formatSeverityLevel(alarmSeverityLevel),
        value: count,
      }))
    : [];

  const handleFaultClick = (severityLevel) => {
    navigate(`/alarms?severity=${severityLevel}`);
  };
  const handleHealthStatusClick = (healthStatus) => {
    navigate(`/device-listing?health=${healthStatus}`);
  };

  const formatInterval = (interval) => {
    if (selectedInterval === "yearly") {
      return interval;
    } else if (selectedInterval === "monthly") {
      const date = new Date(interval);
      return date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
      });
    } else if (selectedInterval === "yearly") {
      const date = new Date(interval);
      return date.toLocaleDateString("en-GB", {
        month: "short",
      });
    } else {
      return interval.split(" ")[0];
    }
  };

  const faultsColors = faultsData.map(({ name }) => {
    if (name === "Critical") return "#FF6B6B";
    if (name === "Major") return "#FFAD01";
    if (name === "Minor") return "#26BF78";
    if (name === "Info") return "#118AB2";
    return "#CCCCCC";
  });

  const trendsColor = (index) => {
    const colors = ["#33A02C", "#1F78B4", "#FB9A99", "#B2DF8A", "#A6CEE3"];
    return colors[index % colors.length];
  };

  const renderCustomLegend = (props) => {
    const { payload } = props;
    return (
      <div style={{ marginRight: "20px" }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`}>
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: COLORS[index % COLORS.length],
                marginRight: "5px",
              }}
            ></span>
            {`${entry.value}: ${entry.payload.value}`}
          </div>
        ))}
      </div>
    );
  };

  const updatePieChartData = (container) => {
    // Calculate average usage
    const totalUsage =
      container?.cpu_usage +
      container?.memory_usage * 100 +
      container?.storage_usge * 100 +
      container?.db_connection;
    const averageUsage = totalUsage / 4;

    const pieData = [
      { name: "Everything looks good", value: averageUsage < 80 ? 100 : 0 },
      { name: "Attention required", value: averageUsage >= 80 ? 100 : 0 },
    ];

    // Normalize values so they sum to 100
    const total = pieData.reduce((sum, entry) => sum + entry.value, 0);
    const normalizedPieData = pieData.map((entry) => ({
      ...entry,
      value: (entry.value / total) * 100,
    }));

    setPieChartData(normalizedPieData);
  };

  const renderPie = (props) => {
    const { payload } = props;
    const total = payload.reduce((acc, entry) => acc + entry.value, 0);

    return (
      <div style={{ marginRight: "20px" }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`}>
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: entry.value < 80 ? "#FF0000" : "#26BF78",
                marginRight: "5px",
              }}
            ></span>
            {`${entry.name}: ${((entry.value / total) * 100).toFixed(2)}%`}
          </div>
        ))}
      </div>
    );
  };

  const totalDeviceCount =
    deviceData.totalDevices + deviceData.waitingForConnectionCount;

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="inc-overview-grid">
          <div className="inc-grid-col-1">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Devices</h3>
                <span className="card-count">{totalDeviceCount} </span>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="deviceCountChart">
                    <div className="device-chart">
                      <div>
                        {" "}
                        {data && data.length > 0 ? (
                          <div className="c-chart">
                            <PieChart width={200} height={232}>
                              <Pie
                                data={data}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="45%"
                                innerRadius={55}
                                outerRadius={70}
                                fill="#8884d8"
                              >
                                {data.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Pie>
                              <Tooltip />
                            </PieChart>
                          </div>
                        ) : (
                          <div>No data to display</div>
                        )}
                      </div>
                      <div className="c-legend">
                        <ul>
                          {data.map((entry, index) => (
                            <li key={index}>
                              <span
                                className="legend-color"
                                style={{
                                  backgroundColor:
                                    COLORS[index % COLORS.length],
                                }}
                              ></span>
                              {entry.name} : <strong>{entry.value}</strong>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Alerts</h3>
                <span className="card-count">
                  {Array.isArray(faults)
                    ? faults.reduce((total, entry) => total + entry.count, 0)
                    : 0}
                </span>
              </div>
              <div className="inc-card-body alarm-chart">
                {loading ? (
                  <Loader />
                ) : faultsData && faultsData.length > 0 ? (
                  <PieChart width={200} height={243}>
                    <Pie
                      data={faultsData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="45%"
                      innerRadius={53}
                      outerRadius={70}
                      fill="#8884d8"
                    >
                      {faultsData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={faultsColors[index % faultsColors.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                ) : (
                  <div>No data to display</div>
                )}
                <div className="fault-legends">
                  {Array.isArray(faults) &&
                    faults.map((entry, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleFaultClick(
                            formatSeverityLevel(entry.alarmSeverityLevel)
                          )
                        }
                      >
                        <strong
                          style={{
                            color:
                              entry.alarmSeverityLevel === "MAJOR"
                                ? "#FFAD01"
                                : faultsColors[index % faultsColors.length],
                          }}
                        >
                          {entry.count}
                        </strong>
                        <span
                          style={{
                            color:
                              entry.alarmSeverityLevel === "MAJOR"
                                ? "#FFAD01"
                                : faultsColors[index % faultsColors.length],
                          }}
                        >
                          {formatSeverityLevel(entry.alarmSeverityLevel)}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Connected Clients</h3>
                <span className="card-count">92</span>
              </div>
              <div className="inc-card-body">
                <div style={{ textAlign: "center" }}>
                  <img src={clientGraph} alt="" />
                </div>
              </div>
            </div>
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Edges</h3>
                <span className="card-count">{edgeCounts.totalCount}</span>
              </div>
              <div className="inc-card-body">
                {edgeCounts && edgeCounts.totalCount > 0 ? (
                  <div className="edge-chart">
                    <Progress
                      percent={
                        edgeCounts.totalCount
                          ? (edgeCounts.activeCount / edgeCounts.totalCount) *
                            100
                          : 0
                      }
                      status="active"
                      showInfo={false}
                    />
                  </div>
                ) : (
                  <div className="edge-chart">No data to display</div>
                )}
                <div className="edge-legend">
                  <ul>
                    <li>
                      <span className="active"></span> Active{" "}
                      <strong>{edgeCounts.activeCount}</strong>
                    </li>
                    <li>
                      <span className="inactive"></span> Inactive{" "}
                      <strong>{edgeCounts.inactiveCount}</strong>
                    </li>
                    <li>
                      <span className="managed"></span> Managed Devices{" "}
                      <strong>{edgeCounts.managedByGateway}</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="inc-card box5">
              <div className="inc-card-title">
                <h3>Device Onboarding Trends</h3>
                <span
                  className="dropdown"
                  id="trendDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>{selectedInterval.charAt(0).toUpperCase() +
                    selectedInterval.slice(1)}{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i></span>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="trendDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("hourly")}
                      >
                        Hourly
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("weekly")}
                      >
                        Weekly
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("monthly")}
                      >
                        Monthly
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("yearly")}
                      >
                        Yearly
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="chart-wrapper">
                    <ResponsiveContainer
                      className="on-trend"
                      width="100%"
                      height={310}
                    >
                      <LineChart
                        data={trendData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="interval"
                          tickFormatter={formatInterval}
                        />
                        <YAxis domain={[0, 200]} />
                        <Tooltip />
                        <Line
                          type="natural"
                          dataKey="SNMP"
                          name="SNMP"
                          stroke="#33A02C"
                        />
                        <Line
                          type="natural"
                          dataKey="TR-069"
                          name="TR-069"
                          stroke="#1F78B4"
                        />
                        <Line
                          type="natural"
                          dataKey="MQTT"
                          name="MQTT"
                          stroke="#FB9A99"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}
                <div className="trends-legends">
                  <ul>
                    <li>
                      <span className="d1"></span>SNMP
                    </li>
                    <li>
                      <span className="d2"></span>TR-069
                    </li>
                    <li>
                      <span className="d3"></span>MQTT
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>System Health</h3>
              <div className="grafna-link">
                <a
                  style={{ cursor: "pointer", color: "purple" }}
                  onClick={() => navigate("/system-info")}
                >
                  More Info
                </a>

                {showPopup && (
                  <div className="popup">
                    <p>
                      <strong>GRAFANA DASHBOARD</strong>
                    </p>
                    <p>Credentials:</p>
                    <p>Username: incx</p>
                    <p>Password: incx@123</p>
                  </div>
                )}
              </div>
            </div>
            <div className="inc-card-body">
              <select
                className="form-select"
                aria-label="Default select example"
                value={selectedContainerId}
                onChange={handleDropdownChange}
              >
                {containerList.map((container) => (
                  <option
                    key={container.container_id}
                    value={container.container_id}
                  >
                    {container.container_name}
                  </option>
                ))}
              </select>

              <div className="dr-status">
                <div>
                  <strong>DR Status</strong>
                  <span>Everything Looks Great!</span>
                </div>
                <div>
                  <img src={thumUp} alt="" />
                </div>
              </div>
              {containerData && (
                <div className="mb-4 mt-3">
                  <div className="inc-sys-health">
                    <div>
                      <img src={cpuIcon} alt="CPU Icon" />
                    </div>
                    <div>
                      <div className="sys-info">
                        <span>CPU Usage</span>
                        <span>{containerData.cpu_usage.toFixed(2)}%</span>
                      </div>
                      <div className="inc-progress cpu">
                        <span
                          style={{ width: `${containerData.cpu_usage}%` }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="inc-sys-health">
                    <div>
                      <img src={memoryIcon} alt="Memory Icon" />
                    </div>
                    <div>
                      <div className="sys-info">
                        <span>Memory Usage</span>
                        <span>
                          {(containerData.memory_usage * 100).toFixed(2)}%
                        </span>
                      </div>
                      <div className="inc-progress memory">
                        <span
                          style={{
                            width: `${containerData.memory_usage * 100}%`,
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="inc-sys-health">
                    <div>
                      <img src={diskIcon} alt="Disk Icon" />
                    </div>
                    <div>
                      <div className="sys-info">
                        <span>Disk Utilization</span>
                        <span>
                          {!isNaN(containerData.storage_usge)
                            ? `${(containerData.storage_usge * 100).toFixed(
                                2
                              )}%`
                            : "N/A"}
                        </span>
                      </div>
                      <div className="inc-progress disk">
                        <span
                          style={{
                            width: `${containerData.storage_usge * 100}%`,
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="inc-sys-health">
                    <div>
                      <img src={dbIcon} alt="DB Icon" />
                    </div>
                    <div>
                      <div className="sys-info">
                        <span>DB Connection</span>
                        <span>{containerData.db_connection}</span>
                      </div>
                      <div className="inc-progress db">
                        <span
                          style={{ width: `${containerData.db_connection}%` }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="node-sec">
                <div className="node-chart">
                  <strong>Container Status</strong>
                </div>
                <div className="deviceCountChart health-chart">
                  <ResponsiveContainer width="100%" height={200}>
                    <PieChart width={200} height={200}>
                      <Pie
                        data={pieChartData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="40%"
                        innerRadius={55}
                        outerRadius={70}
                        fill="#8884d8"
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={
                              entry.name === "Everything looks good"
                                ? "#26BF78"
                                : "#FF6B6B"
                            }
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="node-legends">
                  <ul>
                    <li>
                      <span
                        className="d1"
                        style={{ backgroundColor: "#26BF78" }}
                      ></span>
                      Everything looks good
                    </li>
                    <li>
                      <span
                        className="d2"
                        style={{ backgroundColor: "#FF0000" }}
                      ></span>
                      Attention required
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
