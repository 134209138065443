import React, { useState, useEffect } from "react";
import allApi from "../../../api/allApi";
import closeIcon from "../../../assets/images/close.svg";
import CreatetemplatePopup from "../common/CreatetemplatePopup";
import Loader from "../common/Loader";
import { Modal, Button } from "react-bootstrap";
import errorIcon from "../../../assets/images/error-2.png";
import EditSnmpTemplate from "../../component/common/EditSnmpTemplate";
function SnmpTemplate() {
  const [showPopUp, setShowPopUp] = useState(false);
  const [snmpTemplates, setSnmpTemplates] = useState([]);
  const [searchQueries, setSearchQueries] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [individualCheckboxChecked, setIndividualCheckboxChecked] = useState(
    {}
  );
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [templatesPerPage] = useState(10);

  useEffect(() => {
    fetchSnmpTemplates();
  }, []);

  const fetchSnmpTemplates = async () => {
    setLoading(true);
    setRefreshing(true);
    try {
      const api = new allApi();
      const response = await api.getRequest("api/snmp/templates/all");
      if (response) {
        setSnmpTemplates(response);
      } else {
        console.error(
          "Error fetching SNMP templates: Invalid response or data structure."
        );
      }
    } catch (error) {
      console.error("Error fetching SNMP templates:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const handleAddButtonClick = () => {
    setShowPopUp(true);
  };

  const handleSearchChange = (e, field) => {
    setSearchQueries({ ...searchQueries, [field]: e.target.value });
  };

  const filteredTemplates = snmpTemplates.filter((template) =>
    Object.keys(searchQueries).every((key) =>
      template[key]
        ?.toString()
        .toLowerCase()
        .includes(searchQueries[key].toLowerCase())
    )
  );

  const handleSearchBox = (header) => {
    console.log("Clicked on header:", header);
  };

  const handleSelectAllCheckbox = () => {
    setSelectAllChecked((prev) => !prev);
    const newIndividualCheckboxChecked = {};
    filteredTemplates.forEach((template) => {
      newIndividualCheckboxChecked[template.snmp_template_id] =
        !selectAllChecked;
    });
    setIndividualCheckboxChecked(newIndividualCheckboxChecked);
  };

  const handleIndividualCheckboxChange = (templateId) => {
    setIndividualCheckboxChecked((prev) => ({
      ...prev,
      [templateId]: !prev[templateId],
    }));
  };

  const handleDelete = async (templateIds) => {
    try {
      const api = new allApi();
      const response = await api.deleteRequest(
        `api/snmp/template/delete`,
        templateIds.map((id) => ({ snmp_template_id: id }))
      );
      if (response) {
        fetchSnmpTemplates();
        setShowDeleteModal(false);
      }
    } catch (error) {
      console.error("Error deleting SNMP templates:", error);
    }
  };

  const handleEditTemplate = async (snmp_template_id) => {
    try {
      const templateData = await fetchTemplateById(snmp_template_id);
      setSelectedTemplate(templateData);
      setShowEditPopup(true);
    } catch (error) {
      console.error("Error fetching SNMP template data for editing:", error);
    }
  };
  const fetchTemplateById = async (snmp_template_id) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/snmp/template/${snmp_template_id}`
      );
      return response;
    } catch (error) {
      console.error("Error fetching template by ID:", error);
      throw new Error("Error fetching template by ID");
    }
  };

  const handleTemplateClose = () => {
    setShowPopUp(false);
  };

  const handleSuccess = () => {
    setShowSuccessModal(true);
    fetchSnmpTemplates();
  };

  const handleDeleteButtonClick = () => {
    const selectedTemplateIds = Object.keys(individualCheckboxChecked).filter(
      (key) => individualCheckboxChecked[key]
    );
    if (selectedTemplateIds.length > 0) {
      handleDelete(selectedTemplateIds);
      setShowDeleteModal(true);
    }
  };

  const confirmDelete = () => {
    handleDelete(selectedTemplateIds);
  };

  const handleAddTemplate = () => {
    fetchSnmpTemplates();
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
  };
  const indexOfLastDevice = currentPage * templatesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - templatesPerPage;
  const totalFilteredTemplates = filteredTemplates.length;
  const currentTemplates = filteredTemplates.slice(
    indexOfFirstDevice,
    indexOfLastDevice
  );

  const totalPages = Math.ceil(totalFilteredTemplates / templatesPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <h3>SNMP</h3>
          <div className="inc-card-button">
            <button
              className="text-btn primary-btn"
              onClick={handleAddButtonClick}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              Add
            </button>
            <button
              className="icon-btn"
              title="Refresh"
              onClick={() => fetchSnmpTemplates()}
            >
              {refreshing ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-refresh" aria-hidden="true"></i>
              )}
            </button>
            <button
              className="icon-btn"
              title="Delete"
              onClick={handleDeleteButtonClick}
              disabled={
                Object.keys(individualCheckboxChecked).filter(
                  (key) => individualCheckboxChecked[key]
                ).length === 0
              }
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div className="inc-card-body">
          <div className="inc-card-table">
            <table className="full-table m-head">
              <thead>
                <tr>
                  <th>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        checked={selectAllChecked}
                        onChange={handleSelectAllCheckbox}
                      />

                      <span className="indicator"></span>
                    </label>
                  </th>
                  <th>Template Name</th>
                  <th>Version</th>
                  {/* <th>Read only Community</th> */}
                  {/* <th>Read write Community</th> */}
                  <th>Trap Community</th>
                  <th>Trap host version</th>
                  <th>Trap port</th>
                  <th>Action</th>
                </tr>
                <tr className="search-border">
                  <td></td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.template_name}
                      onChange={(e) => handleSearchChange(e, "template_name")}
                    />
                    <i
                      className="fa fa-sort-amount-desc"
                      onClick={() => handleSearchChange("template_name")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.version}
                      onChange={(e) => handleSearchChange(e, "version")}
                    />
                    <i
                      className="fa fa-sort-amount-desc"
                      onClick={() => handleSearchChange("version")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.trap_community}
                      onChange={(e) => handleSearchChange(e, "trap_community")}
                    />
                    <i
                      className="fa fa-sort-amount-desc"
                      onClick={() => handleSearchChange("trap_community")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.trap_host_version}
                      onChange={(e) =>
                        handleSearchChange(e, "trap_host_version")
                      }
                    />
                    <i
                      className="fa fa-sort-amount-desc"
                      onClick={() => handleSearchChange("trap_host_version")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.trap_port}
                      onChange={(e) => handleSearchChange(e, "trap_port")}
                    />
                    <i
                      className="fa fa-sort-amount-desc"
                      onClick={() => handleSearchChange("trap_port")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td colSpan="7"></td>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="6">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  currentTemplates.map((template, index) => (
                    <tr key={index}>
                      <td>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={
                              individualCheckboxChecked[
                                template.snmp_template_id
                              ] || false
                            }
                            onChange={() =>
                              handleIndividualCheckboxChange(
                                template.snmp_template_id
                              )
                            }
                          />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>{template.template_name || "N/A"}</td>
                      <td>{template.version || "N/A"}</td>
                      {/* <td>{template.read_only_community || "N/A"}</td> */}
                      {/* <td>{template.readwrite_community || "N/A"}</td> */}
                      <td>{template.trap_community || "N/A"}</td>
                      <td>{template.trap_host_version || "N/A"}</td>
                      <td>{template.trap_port || "N/A"}</td>
                      <td>
                        <div className="button-group">
                          <button
                            title="Edit"
                            onClick={() =>
                              handleEditTemplate(template.snmp_template_id)
                            }
                          >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                          </button>
                          <button
                            title="Delete"
                            data-bs-toggle="modal"
                            data-bs-target="#noTemplateSelectedPopup"
                            onClick={() =>
                              handleDelete(template.snmp_template_id)
                            }
                            disabled={
                              !individualCheckboxChecked[
                                template.snmp_template_id
                              ]
                            }
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            <div className="pagination-container">
              <div className="pagination-info">
                {" "}
                <p>
                  Showing{" "}
                  {Math.min(
                    currentPage * templatesPerPage,
                    totalFilteredTemplates
                  )}{" "}
                  of {totalFilteredTemplates} records
                </p>
              </div>
              <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                  Previous
                </button>
                {[...Array(Math.min(totalPages, 10)).keys()].map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageClick(page + 1)}
                    className={currentPage === page + 1 ? "active" : ""}
                  >
                    {page + 1}
                  </button>
                ))}
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
                <button
                  onClick={handleLastPage}
                  disabled={currentPage === totalPages}
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditSnmpTemplate
        showPopUp={showEditPopup}
        handleClose={() => setShowEditPopup(false)}
        headerMessage1="Edit SNMP Template"
        handleSuccess={handleSuccess}
        template={selectedTemplate}
      />
      <CreatetemplatePopup
        showPopUp={showPopUp}
        handleClose={handleTemplateClose}
        headerMessage1="Create SNMP Template"
        closeIcon={closeIcon}
        onSuccess={handleSuccess}
        onTemplateAdd={handleAddTemplate}
      />
      <div
        className={`modal fade`}
        id="noTemplateSelectedPopup"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure want to delete?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={handleDeleteButtonClick}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showSuccessModal}
        onHide={handleSuccessModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert"
      >
        <Modal.Body className="text-center">
          <span className="success-icon">
            <i className="icon done"></i>
          </span>
          <h3>Success</h3>
          <p>Template created Successfully!</p>
          <Button onClick={handleSuccessModalClose}>OK</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SnmpTemplate;
