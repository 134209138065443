import React from "react";
import inclogo from "../../../assets/images/inc-name-logo.png";
import { useState, useEffect } from "react";
import allApi from "../../../api/allApi";
import { useLocation } from "react-router-dom";

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const location = useLocation();
  //const searchParams = new URLSearchParams(location.search);
 // const token = searchParams.get("token");


  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');

      if(token){
      try {
        const api = new allApi();
        const response = await api.getRequest(`api/user/verify?token=${token}`);
        if(response){
          setUserData(response);
          console.log("userdata",response);
        }
          
      } catch (error) {
        setError('Token not found', error);

      }
    }else{
      setError("Token not found");
    }
    };

    fetchData();
  }, []);

  /*const handleAddUser = async (e) => {
    e.preventDefault();

    try {
      const api = new allApi();
      const userData = {
        //token,
        firstname: firstName,
        lastname: lastName,
        username,
        email,
        password,
        address,
        state,
        country,
        role: selectedRole,
      };
      const response = await api.postRequest("api/user/edit-tempUser", "", userData);

      if (response) {
        setResp(response);
        console.log('User added successfully', response);
        //onSuccess("User added successfully");
      } else {
        console.error("Failed to add user");
      }
    } catch (error) {

      console.error('Failed to add user:', error);
    }
  };*/


  return (
    <div>
      <section className="inc-login">
        <div className="login-form">
          <div className="login-form-container userprofile">
            <div className="login-text">
              <img src={inclogo} alt="inc logo" className="inc-logo" />
              <h1>User Profile</h1>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  Firstname
                </label>
                <input type="text"
                  className="form-control"
                  id="firstName"
                  
                  value={userData.firstName}
                  />
              </div>
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  Lastname
                </label>
                <input type="text"
                  className="form-control"
                  id="lastName"
                  value={userData.lastName}
                  />
              </div>
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  Username
                </label>
                <input type="text"
                  className="form-control"
                  id="username"
                  value={userData.username}
                   />
              </div>
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  Email
                </label>
                <input type="text"
                  className="form-control read-only-input"
                  id="email"
                  value={userData.email}
                 
                  readOnly />
              </div>
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  Password
                </label>
                <input type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  Confirm Password
                </label>
                <input type="password"
                  className="form-control"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)} />
              </div>
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  Role
                </label>
                <input className="form-select"
                  id="role"
                  value={userData.selectedRole}
                   />
              </div>
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  Address
                </label>
                <input type="text"
                  className="form-control"
                  id="address"
                  value={userData.address}
                  onChange={(e) => setAddress(e.target.value)} />
              </div>
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  State
                </label>
                <input type="text"
                  className="form-control"
                  id="state"
                  value={userData.state}
                  onChange={(e) => setState(e.target.value)} />
              </div>
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  Country
                </label>
                <input type="text"
                  className="form-control"
                  id="country"
                  value={userData.country}
                  onChange={(e) => setCountry(e.target.value)} />
              </div>
              <div className="col-lg-12 user-footer">
                <button type="button" className="btn btn-outline">
                  Cancel
                </button>
                <button type="submit" className="text-btn primary-btn"
                 >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  );
};

export default UserProfile;
