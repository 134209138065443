import React, { useEffect, useState } from "react";
import allApi from "../../../api/allApi";
import { useLocation } from "react-router-dom";
import Loader from "../common/Loader";
function App() {
  const [totalDevices, setTotalDevices] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deviceListings, setDeviceListings] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [devicesPerPage] = useState(10);
  const [isSearchOpen, setIsSearchOpen] = useState({
    device_name: false,
    alarmType: false,
    severityLevel: false,
    is_acknowledged: false,
    is_autoclear: false,
    notification: false,
    alarm_behaviour: false,
    alarm_description: false,
    alarm_time: false,
    acked_time: false,
    cleared_time: false,
    is_read: false,
    event_name: false,
    device_ipv6: false,
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [alarmToDelete, setAlarmToDelete] = useState(null);
  const location = useLocation();
  const { alarms } = location.state || {};
  const queryParams = new URLSearchParams(location.search);
  const severityLevel = queryParams.get("severity");
  const [faults, setFaults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [lastPage,setLastPage] = useState();
 

  useEffect(() => {
    if (alarms && alarms.length > 0) {
      setDeviceListings(alarms);
      setFilteredDevices(alarms);
      setTotalDevices(alarms.length);
    } else {
      fetchAlarmData();
    }
  }, []);

  useEffect(() => {
    const severityQueryParam = queryParams.get("severity");
    if (severityQueryParam) {
      if (deviceListings && deviceListings.length > 0) {
        const filteredDevices = deviceListings.filter(
          (alarm) => alarm.severityLevel == severityQueryParam
        );
        setFilteredDevices(filteredDevices);
      }
    }
  }, [deviceListings]);

  async function fetchAlarmData() {
    setLoading(true);
    setRefreshing(true);
    try {
      const api = new allApi();
      const alarmResponse = await api.getRequest("api/alarms/all");
      if (Array.isArray(alarmResponse)) {
        setDeviceListings(alarmResponse);
        setFilteredDevices(alarmResponse);
        setTotalDevices(alarmResponse.length);
        
      } else {
        console.error("Fetched data is not an array:", alarmResponse);
        setDeviceListings([]);
        setFilteredDevices([]);
        setTotalDevices(0);
        
      }
    } catch (error) {
      console.error("Error fetching alarm data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }

  const handleDelete = async (alarmId) => {
    try {
      const api = new allApi();
      await api.deleteRequest(`api/alarms/${alarmId}`);
      fetchAlarmData();
      setAlarmToDelete(null);
    } catch (error) {
      console.error("Error deleting alarm:", error);
    }
  };

  const handleSelectAll = (isChecked) => {
    setSelectAllChecked(isChecked);
    if (isChecked) {
      setSelectedCheckboxes(filteredDevices.map((device) => device.alarm_id));
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleSearch = (e) => {
    if (e.key === "Backspace") {
      e.target.value = "";
      fetchAlarmData();
    } else {
      setFilteredDevices(
        currentDevices.filter((list) =>
          list[e.target.name]
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const handleSearchBox = (header) => {
    Object.keys(isSearchOpen).find((key) => {
      if (key == header) {
        isSearchOpen[key] = !isSearchOpen[key];
      } else {
        isSearchOpen[key] = false;
      }
    });
    setIsSearchOpen({ ...isSearchOpen });
  };

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const totalFilteredDevices = filteredDevices.length;
  const currentDevices = filteredDevices.slice(indexOfFirstDevice, indexOfLastDevice);

  const handleFilterDevice = () => {
    const currentDevices = filteredDevices.length
      ? filteredDevices.slice(
          (currentPage - 1) * devicesPerPage,
          currentPage * devicesPerPage
        )
      : [];

    return currentDevices;
  };

  const filteredFaults = faults.filter(
    (fault) => fault.alarmSeverityLevel === severityLevel
  );

  const successhandleClose = () => {
    setShow(false);
    setAlarmToDelete(null);
  };
  const totalPages = Math.ceil(totalFilteredDevices / devicesPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  

 

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Alerts</h3>
            <div className="inc-card-button">
              <button
                className="icon-btn"
                title="Refresh"
                onClick={() => fetchAlarmData()}
              >
                {refreshing ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                )}{" "}
              </button>

              <button className="icon-btn" title="Download">
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div>
              <div className="inc-card-table">
                <table className="full-table m-head">
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        Device Name
                        <i
                          className="fa fa-sort-amount-desc"
                          onClick={() => handleSearchBox("device_name")}
                          aria-hidden="true"
                        ></i>
                      </th>
                      <th>
                        Device IP
                        <i
                          className="fa fa-sort-amount-desc"
                          onClick={() => handleSearchBox("ipv4_address")}
                          aria-hidden="true"
                        ></i>
                      </th>
                   
                      <th>
                        Severity Level
                        <i
                          className="fa fa-sort-amount-desc"
                          aria-hidden="true"
                        ></i>
                      </th>

                   
                      <th>
                        Alerts Behaviour
                        <i
                          className="fa fa-sort-amount-desc"
                          onClick={() => handleSearchBox("alarm_behaviour")}
                          aria-hidden="true"
                        ></i>
                      </th>
                  
                      <th>
                        Event Name
                        <i
                          className="fa fa-sort-amount-desc"
                          onClick={() => handleSearchBox("event_name")}
                          aria-hidden="true"
                        ></i>
                      </th>
                      <th>
                        Alerts Time
                        <i
                          className="fa fa-sort-amount-desc"
                          onClick={() => handleSearchBox("alarm_time")}
                          aria-hidden="true"
                        ></i>
                      </th>
                      <th>
                        Alerts Description
                        <i
                          className="fa fa-sort-amount-desc"
                          onClick={() => handleSearchBox("alarm_description")}
                          aria-hidden="true"
                        ></i>
                      </th>
                    </tr>
                    <tr className="search-border">
                      <td></td>
                     
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="device_name"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="ipv4_address"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                    
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="severityLevel"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                  
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="alarm_behaviour"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                    
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="event_name"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                      
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="alarm_time"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="alarm_description"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                    </tr>
                  </thead>
                  {loading ? (
                      <tr>
                        <td colSpan="6">
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                  totalFilteredDevices === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="9">
                          <div style={{ textAlign: "center" }}>
                            <p>No alerts to display</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      
                      {handleFilterDevice().map((device, index) => (
                        <tr key={index}>
                          <td>
                            
                          </td>
                          <td>{device.device_name || "--"}</td>
                         
                          <td>{device.deviceId.ipv4_address}</td>
                          <td>{device.severityLevel || "--"}</td>
                       
                          <td>{device.alarm_behaviour || "--"}</td>
                          <td>{device.event_name || "--"}</td>
                          <td>{device.alarm_time || "--"}</td>
                          <td>{device.alarm_description || "--"}</td>
                        </tr>
                      ))}
                    </tbody>
                  ))}
                </table>
              </div>
             
              <div className="pagination-container">
              <div className="pagination-info">
                <p>
                  Showing{" "}
                  {Math.min(currentPage * devicesPerPage, totalFilteredDevices)}{" "}
                  of {totalFilteredDevices} records
                </p>
              </div>
                <div className="pagination">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </button>
          {[...Array(Math.min(totalPages, 10)).keys()].map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page + 1)}
              className={currentPage === page + 1 ? "active" : ""}
            >
              {page + 1}
            </button>
          ))}
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
          <button onClick={handleLastPage} disabled={currentPage === totalPages}>
            Last Page
          </button>
          </div>
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
