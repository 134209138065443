import React from "react";

function Reports() {
  return (
    <div>
      <h4>Review</h4>
      <p>
        Review the report profile, Filter and widgets and generate a Preview of
        how report would look when generated.
      </p>
      <div class="accordion mt-4" id="accordionReview">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Report Profile
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionReview"
          >
            <div class="accordion-body">
              <div className="d-flex">
                <div className="p-report">
                  <label className="form-label">Report Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="r-profile">
                  <label className="form-label">Description</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Widgets
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionReview"
          >
            <div class="accordion-body">
              <div className="inc-card-table">
                <table className="m-head">
                  <thead>
                    <tr>
                      <th>Widgets Name</th>
                      <th>Descriptions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Network Growth</td>
                      <td>
                        Lorem Ipsum has been the industry's standard dummy text
                      </td>
                    </tr>
                    <tr>
                      <td>Network Growth</td>
                      <td>
                        Lorem Ipsum has been the industry's standard dummy text
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Filter
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionReview"
          >
            <div class="accordion-body">
              <div className="r-filter">
                <label className="form-label">Filter Name</label>
                <input type="text" className="form-control" />
              </div>
              <div className="inc-card-table">
                <table className="full-table m-head">
                  <thead>
                    <tr>
                      <th>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </th>
                      <th>Organizations Name</th>
                      <th>Locations</th>
                      <th>Devices</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>INC Noida</td>
                      <td>Noida</td>
                      <td>4 Devices</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
