import React from "react";
import Loader from "../common/Loader";
import { useNavigate } from "react-router-dom";

function ReportList() {
    const navigate = useNavigate();

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <h3>Reports</h3>
          <div className="inc-card-button">
            <button
              className="text-btn primary-btn"
              role="button"
              onClick={() => {
                navigate("/reports-listing");
              }}
            >
              <i className="fa fa-plus" aria-hidden="true"></i> Create Report
            </button>
            <button className="icon-btn" title="Refresh">
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </button>
            <button className="icon-btn" title="Delete">
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div className="inc-card-body">
          <div className="inc-card-table">
            <table className="full-table m-head">
              <thead>
                <tr>
                  <th>
                    <label className="checkbox">
                      <input type="checkbox" />
                      <span className="indicator"></span>
                    </label>
                  </th>
                  <th>Report Name</th>
                  <th>Description</th>
                  <th>Schedule</th>
                  <th>Created On</th>
                  <th>Last Report</th>
                  <th>Next Report</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox" />
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>StarLoom</td>
                  <td>Aurora</td>
                  <td>Lane</td>
                  <td>aurora@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>
                  <td>
                    <div className="button-group">
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox" />
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>QuantumQuasar</td>
                  <td>Elias </td>
                  <td>Flynn</td>
                  <td>elias@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>
                  <td>
                    <div className="button-group">
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox" />
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>MysticMuse</td>
                  <td>Serena </td>
                  <td>Morgan</td>
                  <td>serena@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>
                  <td>
                    <div className="button-group">
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox" />
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>LunaLattice</td>
                  <td>Asher</td>
                  <td>Reid</td>
                  <td>asher@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>
                  <td>
                    <div className="button-group">
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox" />
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>EchoEnigma</td>
                  <td>Maya </td>
                  <td>Bennett</td>
                  <td>maya@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>
                  <td>
                    <div className="button-group">
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox" />
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>CelestialCipher</td>
                  <td>Oliver </td>
                  <td>Hayes</td>
                  <td>oliver@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>
                  <td>
                    <div className="button-group">
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <div></div>
            <div className="pagination">
              <button>Previous</button>

              <button>Next</button>
              <button>Last Page</button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="tenantModalToggle"
        aria-hidden="true"
        aria-labelledby="tenantModalToggleLabel"
        tabindex="-1"
      >
        <div className="modal-dialog modal-dialog-centered create-usr-pop">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Tenant</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="row">
                  <div class="col-lg-6">
                    <label for="organization" class="col-form-label">
                      Organization Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      id="organization"
                      name="organization"
                      value=""
                    />
                  </div>
                  <div class="col-lg-6">
                    <label for="address" class="col-form-label">
                      Address<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      id="address"
                      name="address"
                      value=""
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <label for="firstname" class="col-form-label">
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      id="firstname"
                      name="firstname"
                      value=""
                    />
                  </div>
                  <div class="col-lg-6">
                    <label for="lastname" class="col-form-label">
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      id="lastname"
                      name="lastname"
                      value=""
                    />
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-6">
                    <label for="phonenumber" class="col-form-label">
                      Phone No<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      id="phonenumber"
                      name="phonenumber"
                      value=""
                    />
                  </div>
                  <div class="col-lg-3">
                    <label for="lastname" class="col-form-label">
                      EDGE Support<span className="text-danger">*</span>
                    </label>
                    <select className="form-select">
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label for="lastname" class="col-form-label">
                      Status<span className="text-danger">*</span>
                    </label>
                    <select className="form-select">
                      <option>Active</option>
                      <option>Inactive</option>
                    </select>
                  </div>
                </div>
              </form>

              <div className="modal-footer pb-0">
                <button
                  type="button"
                  className="btn btn-outline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button type="button" className="btn btn-fill">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportList;
