import React, { useState } from "react";
import OTAs from "../../../assets/images/ota-sc.png";
import OTAupi from "../../../assets/images/ota-upi.png";
import OTAupd from "../../../assets/images/ota-upd.png";
import OTAf from "../../../assets/images/ota-fl.png";

function OTAManagement() {
  const [isEnabled, setIsEnabled] = useState(false);
  const [time, setTime] = useState(null);

  const toggleSwitch = () => {
    setIsEnabled(!isEnabled);
  };

  const handleSave = () => {};

  const handleCancel = () => {};

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <ul className="nav nav-underline" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="overview-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#overview"
                  type="button"
                  role="tab"
                  aria-controls="overview"
                  aria-selected="true"
                >
                  Overview
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="scheduled-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#scheduled"
                  type="button"
                  role="tab"
                  aria-controls="scheduled"
                  aria-selected="false"
                >
                  Scheduled
                </button>
              </li>
            </ul>
          </div>
          <div className="inc-card-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="overview"
                role="tabpanel"
                aria-labelledby="overview-tab"
              >
                <div className="ota-graphics">
                  <div className="ota-card dtype-count">
                    <div>
                      <strong>60</strong>
                      <span className="snmp">SNMP</span>
                    </div>
                    <div>
                      <strong>60</strong>
                      <span className="mqtt">MQTT</span>
                    </div>
                    <div>
                      <strong>60</strong>
                      <span className="tr069">TR-069</span>
                    </div>
                  </div>
                  <div className="ota-card ota-count">
                    <div>
                      <span className="sc">
                        <img src={OTAs} alt="Device Schedule" />
                      </span>
                    </div>
                    <div>
                      <strong>23</strong>
                      <span>Device Schedule</span>
                    </div>
                  </div>
                  <div className="ota-card ota-count">
                    <div>
                      <span className="upi">
                        <img src={OTAupi} alt="Device Updating" />
                      </span>
                    </div>
                    <div>
                      <strong>15</strong>
                      <span>Device Updating</span>
                    </div>
                  </div>
                  <div className="ota-card ota-count">
                    <div>
                      <span className="fl">
                        <img src={OTAf} alt="Device Failed" />
                      </span>
                    </div>
                    <div>
                      <strong>03</strong>
                      <span>Device Failed</span>
                    </div>
                  </div>
                  <div className="ota-card ota-count">
                    <div>
                      <span className="upd">
                        <img src={OTAupd} alt="Device Updated" />
                      </span>
                    </div>
                    <div>
                      <strong>82</strong>
                      <span>Device Updated</span>
                    </div>
                  </div>
                </div>
                <div className="ota-filter">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <select className="form-select">
                      <option>Any Device</option>
                      <option>Device 01</option>
                    </select>
                    <select className="form-select">
                      <option>Any Status</option>
                      <option>Status 01</option>
                    </select>
                  </div>
                  <div>
                    <button className="text-btn primary-btn">Updates</button>
                    <button className="text-btn primary-btn">Schedule</button>
                  </div>
                </div>
                <div className="inc-card-table">
                  <table className="full-table m-head">
                    <thead>
                      <tr>
                        <th>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </th>
                        <th>Model</th>
                        <th>Serial</th>
                        <th>MAC</th>
                        <th>Current Firmware</th>
                        <th>New Firmware</th>
                        <th>Update Status</th>
                        <th>Next Steps</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="scheduled"
                role="tabpanel"
                aria-labelledby="scheduled-tab"
              >
                <div className="inc-card-table">
                  <table className="full-table m-head">
                    <thead>
                      <tr>
                        <th>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </th>
                        <th>Model</th>
                        <th>Serial</th>
                        <th>MAC</th>
                        <th>Current Firmware</th>
                        <th>New Firmware</th>
                        <th>Update Status</th>
                        <th>Next Steps</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="pagination-container">
              <div className="pagination">
                <button>Previous</button>
                <button>Next</button>
                <button>Last Page</button>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

export default OTAManagement;
